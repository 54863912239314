<template>
  <div class="content-admin m-auto">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a :class="isActive[0]" class="nav-link" href="#" @click="navToCateList">商品管理</a>
      </li>
      <li class="nav-item">
        <a :class="isActive[1]" class="nav-link" href="#" @click="navToSlide">轮播图片管理</a>
      </li>
      <li class="nav-item">
        <a :class="isActive[2]" class="nav-link" href="#" @click="navToSale">特价信息管理</a>
      </li>
      <li class="nav-item">
        <a :class="isActive[3]" class="nav-link" href="#" @click="navToOtherInfo">报价/联系方式</a>
      </li>
      <li class="nav-item">
        <a :class="isActive[4]" class="nav-link" href="#" @click="navToWorker">师傅管理</a>
      </li>
    </ul>

    <div v-if="tabNum === 1">
      <div class="title-page">
        <h3 class="float-left">{{parentNavName}}</h3>
        <ul class="pagination float-right">
          <li class="page-item" :class="pageDisable[0]" @click="goPage('previous')">
            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">上一页</a>
          </li>
          <li class="page-item active" aria-current="page">
            <a class="page-link" href="#"> 第 {{ pageNum }} 页 <span class="sr-only"></span></a>
          </li>
          <li class="page-item" :class="pageDisable[1]" @click="goPage('next')">
            <a class="page-link" href="#">下一页</a>
          </li>
        </ul>
      </div>
      <!--      分类列表-->
      <div class="cate-box">
        <div v-for="cate in cateList" class="cate-item" :key="cate.id">
          <b-card :img-src="cate.imgUrl" img-alt="Image" img-top tag="article" class="mb-2">
            <b-button href="#" variant="primary" @click="loadCateOrGoodList(cate)">{{ cate.title }}</b-button>
            <a href="#" @click="modifyCate(cate)"><img src="../../static/modify.png" data-toggle='modal'
                                                       data-target="#modalAction"/></a>
            <a href="#" @click="deleteCateAction(cate)" data-toggle="modal" data-target="#deleteModal"><img
                src="../../static/delete.png"/></a>
          </b-card>
        </div>
        <div class="cate-item" data-toggle='modal' data-target="#modalAction" @click="addCate">
          <img src="../../static/add.png" style="width: 240px;"/>
          <br/>
          <button href="#" class="btn btn-success" style="margin-top: 10px;float:right;" v-if="parentNavName == '全部分类' ">添加分类</button>
          <button href="#" class="btn btn-success" style="margin-top: 10px;float:right;" v-else>添加物品</button>
        </div>
      </div>
      <!--      分类列表 end -->

    </div>
    <!-- 轮播图 -->
    <div v-else-if="tabNum === 2" class="cate-box">
      <div v-for="slide in slideInfos" :key="slide.id">
        <img :src="slide.imgUrl" class="rounded" alt="Cinque Terre" width="400">
        <div style="display: -webkit-flex;display: flex;justify-content:center;margin: 5px 0;">
          <a href="#" @click="deleteSlide(slide)"><img src="../../static/delete.png" alt="" style=""
                                                       width="30px;"></a>
        </div>
      </div>
      <a href="#" @click="addSlide" data-toggle='modal' data-target="#modalAction">
        <img src="../../static/add.png" alt="" style="margin-top:10px;">
      </a>
    </div>
    <div v-else-if="tabNum === 3" class="cate-box">
      <div class="form-inline">
        <label for="sale">特价信息&nbsp;</label>
        <textarea rows="10" cols="40" class="form-control" id="sale" v-model="saleInfo.info"/>
        <button type="submit" class="btn btn-primary" @click="modifySaleInfo">修改</button>
      </div>
    </div>
    <div v-else-if="tabNum === 4">
      <div class="title-page">
        <h3 class="float-left">商品列表</h3>
        <ul class="pagination float-right">
          <li class="page-item" :class="pageDisable[0]" @click="goPage('previous')">
            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">上一页</a>
          </li>
          <li class="page-item active" aria-current="page">
            <a class="page-link" href="#"> 第 {{ pageNum }} 页 <span class="sr-only"></span></a>
          </li>
          <li class="page-item" :class="pageDisable[1]" @click="goPage('next')">
            <a class="page-link" href="#">下一页</a>
          </li>
        </ul>
      </div>
      <div class="cate-box">
        <div v-for="good in goodList" class="cate-item" :key="good.id">
          <b-card :img-src="good.imgUrl" img-alt="Image" img-top tag="article" class="mb-2">
            <b-button href="#" variant="primary">{{ good.title }}</b-button>
            <a href="#" @click="modifyGood(good)"><img src="../../static/modify.png" data-toggle='modal'
                                                       data-target="#modalAction"/></a>
            <a href="#" @click="deleteGoodAction(good)"><img src="../../static/delete.png"
                                                             data-toggle="modal" data-target="#deleteModal"/></a>
          </b-card>
        </div>
        <div @click="addGood()" class="cate-item" data-toggle='modal' data-target="#modalAction">
          <img src="../../static/add.png" style="width: 240px;"/>
          <br/>
          <button href="#" class="btn btn-success" style="margin-top: 10px;">添加商品</button>
        </div>
      </div>
    </div>
    <!-- 管理报价和联系方式 start -->
    <div v-else-if="tabNum === 5">
      <div class="cate-box">
        <div v-for="info in otherInfos" class="cate-item" :key="info.id">
          <b-card :img-src="info.imgUrl" img-alt="Image" img-top tag="article" class="mb-2">
            <b-button href="#" variant="primary">{{ info.title }}</b-button>
            <a href="#" @click="modifyOther(info)"><img src="../../static/modify.png" data-toggle='modal'
                                                        data-target="#modalAction"/></a>
          </b-card>
        </div>
      </div>
    </div>
    <!-- 管理报价和联系方式 end -->
    <!--    师傅管理-->
    <div v-else>
      <div class="title-page">
        <div class="float-left worker-oper">
          <!--          <div class="input-group">
                      <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                      <span class="glyphicon glyphicon-search" aria-hidden="true">搜索</span>
                    </div>-->
          添加人员
          <b-button variant="primary" @click="addWorker">
            <b-icon-plus></b-icon-plus>
          </b-button>
          <!--          <span><input placeholder="请输入师傅称呼" value="{{this.workerKey}}" /></span>-->
          <b-nav-form class="search-box">
            <b-form-input size="sm" class="mr-sm-2" placeholder="请输入师傅称呼" v-model="workerKey"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" @click="searchWorker">
              <b-icon icon="search" aria-hidden="true"></b-icon>
            </b-button>
          </b-nav-form>
        </div>
        <ul class="pagination float-right">
          <li class="page-item" @click="listWorker(action = 'pre')">
            <a class="page-link" href="#" tabindex="-1">上一页</a>
          </li>
          <li class="page-item active" aria-current="page">
            <a class="page-link" href="#"> 第 {{ this.workerInfo.pageable.pageNumber + 1 }} 页 <span
                class="sr-only"></span></a>
          </li>
          <li class="page-item" @click="listWorker(action='next')">
            <a class="page-link" href="#">下一页</a>
          </li>
        </ul>
      </div>
      <div>

        <table class="table table-bordered">
          <thead>
          <tr>
            <td>序号</td>
            <td>称呼</td>
            <td>电话</td>
            <td>备注</td>
            <td>工种</td>
            <td>位置</td>
            <td>操作</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in workerInfo.content">
            <td>{{ item.id }}</td>
            <td><input v-model="item.title"/></td>
            <td><input v-model="item.telephone"/></td>
            <td><input v-model="item.info"/></td>
            <td>
              <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="item.workerType" :aria-describedby="ariaDescribedby" :name="item.id+item.title"
                              value="1">量尺
                </b-form-radio>
                <b-form-radio v-model="item.workerType" :aria-describedby="ariaDescribedby" :name="item.id+item.title"
                              value="2">安装
                </b-form-radio>
              </b-form-group>
            </td>
            <td v-if=" item.latitude == undefined ">
              0,0
            </td>
            <td v-else>{{ item.latitude + "," + item.longitude }}</td>
            <td>
              <b-button variant="danger" size="sm" :data-id="item.id" @click="deleteWorker">删除</b-button>
              |
              <b-button variant="primary" :data-index="index" @click="modifyWorker">保存</b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 删除提醒模态框 -->
    <div class="modal fade" id="deleteModal">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">

          <!-- 模态框头部 -->
          <div class="modal-header">
            <h4 class="modal-title">提示</h4>
            <button type="button" class="close" data-dismiss="modal" @click="clearId">&times;</button>
          </div>

          <!-- 模态框主体 -->
          <div class="modal-body" v-if="JSON.stringify(deleteCate) != '{}' ">
            <strong>{{ deleteCate.title }}</strong> 将被删除,数据不可恢复
          </div>
          <div class="modal-body" v-else-if="JSON.stringify(deleteGood) != '{}'">
            <strong>{{ deleteGood.title }}</strong> 将被删除,数据不可恢复
          </div>

          <!-- 模态框底部 -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="submitDelete('cate')"
                    v-if=" JSON.stringify(deleteCate) != '{}'">确认
            </button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="submitDelete('good')"
                    v-else-if="JSON.stringify(deleteGood) != '{}'">确认
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加修改动作模态框 -->
    <div class="modal fade" id="modalAction">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">

          <!-- 模态框头部 -->
          <div class="modal-header">
            <h4 class="modal-title" v-if="actionType == 1">修改分类</h4>
            <h4 class="modal-title" v-if="actionType == 2">添加分类</h4>
            <h4 class="modal-title" v-if="actionType == 3">修改商品</h4>
            <h4 class="modal-title" v-if="actionType == 4">添加商品</h4>
            <h4 class="modal-title" v-if="actionType == 5">添加幻灯片</h4>
            <h4 class="modal-title" v-if="actionType == 6">修改图片</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>

          <!-- 模态框主体 -->
          <div class="modal-body">
            <div class="form-group">
              <label for="title" v-if="actionType < 4 ">名称</label>
              <input type="email" class="form-control" id="title" v-model="formDataTemp['title']"
                      v-if="actionType == 1">
              <input type="email" class="form-control" id="title" v-model="formDataTemp['title']"
                     v-else-if="actionType == 2">
              <input type="email" class="form-control" id="title" v-model="formDataTemp['title']"
                     v-else-if="actionType == 3">
              <input type="email" class="form-control" id="title" v-model="formDataTemp['title']"
                     v-else-if="actionType == 4">
            </div>
            <div class="form-group">
              <label for="picture">上传图片</label>
              <span v-if=" actionType == 1 || actionType == 3"
                    style="font-size:14px;margin-left:10px;color:red;">如不修改则不必上传图片</span>
              <input type="file" class="form-control" id='picture' accept=".png, .jpg, .gif"
                     @change="fileUpload">
            </div>
          </div>

          <!-- 模态框底部 -->
          <div v-if="loading" style="display: flex;justify-content: center;padding: 10px;">
            <span style="font-size: larger" >文件上传中~~~</span>
            <b-loading v-if="loading" variant="success" type="grow" style="display: flex;justify-content: center;"></b-loading>
          </div >
          <div class="modal-footer" v-else>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitModifyCate"
                    v-if="actionType == 1" >提交修改
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitAddCate"
                    v-else-if="actionType == 2">提交
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitModifyGood"
                    v-else-if="actionType == 3">提交修改
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitAddGood"
                    v-else-if="actionType == 4">提交
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitAddSlide"
                    v-else-if="actionType == 5">增加
            </button>
            <button type="button" class="btn btn-success" data-dismiss="modal" @click="submitModifyOther"
                    v-else-if="actionType == 6">修改
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '../../util.js';
import $ from 'jquery';
import 'bootstrap';

export default {
  mounted() {
    console.log('mount')
    let userinfoUrl = util.serverUrl + '/user/info'
    this.$http.get(userinfoUrl).then(function (res) {
      console.log('请求成功',res.status);

    }, function (res) {
      console.log('res.status',res.status)
      if (res.status == 401) {
        this.$router.push("/login");
      }
    });
  },
  methods: {
    searchWorker(){
      console.log('search')
      this.listWorker('no')
    },
    //加载分类信息
    loadCateList() {
      console.log('请求分类数据');
      let page = this.pageNum - 1;
      let cateUrl = util.serverUrl + '/cate?page=' + page + '&parentId=' + this.parentCateId;
      this.$http.get(cateUrl).then(function (res) {
        console.log('请求成功');
        this.cateList = res.data.content;
        this.changePreLast(res.data);
      }, function (res) {
        alert('请联系管理员');
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    // 根据parentId 判断是不是添加的子分类
    loadCateOrGoodList(cate, from = 'cate') {

      if (from == 'cate') {
        console.log('从分类页进来');
        this.parentNavName = '< ' + cate.title
        this.pageNum = 1;
      } else {
        console.log('翻页,或刷新');
      }

      /*      if (cate != null && cate != undefined) {
              this.addTocateId = cate.id;
            }*/
      let page = this.pageNum - 1;
      // this.addTocateId = cate.id;
      this.parentCateId = cate.id
      console.log('分类:' + this.parentCateId);
      //todo 判断cate的parentId 已判断是否需要进入子分类
      if (cate.parentId == 0) {
        //顶级类加载子分类
        console.log('顶级类加载子分类')

        this.loadCateList()
      } else {
        //子类加载商品
        console.log('子类加载商品')
        let cateUrl = util.serverUrl + '/good/' + this.parentCateId + '?page=' + page;
        this.$http.get(cateUrl).then(function (res) {
          console.log('请求成功');
          this.goodList = res.data.content;
          this.changePreLast(res.data);
          this.tabNum = 4;
        }, function (res) {
          alert('请联系管理员');
          if (res.status == 401) {
            this.$router.push("/login");
          }
        });
      }
    },
    addCate() {
      console.log('add cate');
      this.actionType = 2;
      this.formDataTemp = {}
      this.clearInput()
    },
    deleteCateAction(cate) {
      console.log('删除操作，出现提示');
      this.deleteCate = cate;
      this.deleteGood = {};
    },
    submitDelete(type) {
      if (type == 'cate') {
        console.log('提交删除分类' + this.deleteCate.title);
        let reqUrl = util.serverUrl + '/cate/' + this.deleteCate.id;
        this.$http.delete(reqUrl).then(function (res) {
          this.loadCateList();
          alert('删除成功');
        }, function (res) {
          // console.log(JSON.stringify(res))
          alert(res.data.msg);
          if (res.status == 401) {
            this.$router.push("/login");
          }
        });
      } else if (type == 'good') {
        console.log('提交删除商品' + this.deleteGood.title);
        let reqUrl = util.serverUrl + '/good/' + this.deleteGood.id;
        this.$http.delete(reqUrl).then(function (res) {
          let cate = {};
          cate['id'] = this.parentCateId;
          this.loadCateOrGoodList(cate, 'page');
          alert('删除成功');
        }, function (res) {
          // console.log(JSON.stringify(res))
          alert(res.data.msg);
          if (res.status == 401) {
            this.$router.push("/login");
          }
        });
      }

    },
    modifyCate(cate) {
      this.actionType = 1;
      console.log('modify cate:'+JSON.stringify(cate));
      this.formDataTemp = JSON.parse(JSON.stringify(cate));

    },
    submitModifyCate() {
      let reqUrl = util.serverUrl + "/cate/" + this.formDataTemp.id;
      this.$http.post(reqUrl, this.formDataTemp).then(function () {
        this.loadCateList();
        alert('修改成功');
      }, function (res) {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    navToCateList() {
      this.isActive = ['active', '', '', ''];
      this.tabNum = 1;
      this.formDataTemp = {};
      this.pageNum = 1;
      this.parentCateId = 0;
      this.parentNavName = '全部分类'
      console.log('pageNum:' + this.pageNum);
      this.loadCateList();
    },
    deleteGoodAction(good) {
      this.deleteGood = JSON.parse(JSON.stringify(good));
      console.log('delete good:' + JSON.stringify(this.deleteGood));
      this.deleteCate = {};
    },
    modifyGood(good) {
      console.log('modify good');
      this.actionType = 3;
      this.formDataTemp = JSON.parse(JSON.stringify(good));
    },
    modifyOther(info) {
      console.log('modify other info');
      this.actionType = 6;
      this.formDataTemp = JSON.parse(JSON.stringify(info));
    },
    addGood() {
      console.log('add good')
      this.actionType = 4;
      this.formDataTemp = {};
      this.clearInput()
    },
    clearId() {
      console.log('取消删除');
      this.deleteGood = {};
      this.deleteCate = {};
    },
    clearInput(){
      console.log('clear input')
      let picEle = document.getElementById('picture')
      picEle.value = ''
    },
    fileUpload(event) {
      this.loading = true
      let files = event.target.files;
      console.log('file:' + JSON.stringify(files));
      let formData = new FormData();
      formData.append('file', files[0]);
      if (files[0] == null) {
        console.log('没有文件');
        return;
      }
      console.log(formData);
      let reqUrl = util.serverUrl + '/file';
      this.$http.post(reqUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(function (res) {
        // console.log(JSON.stringify(res));
        this.formDataTemp['imgUrl'] = res.body.path;
        console.log(JSON.stringify(this.formDataTemp));
        this.loading = false
      }, function (res) {
        console.log('error');
        this.loading = false
        alert(res.data.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    submitAddCate() {
      // console.log('data:'+JSON.stringify(this.formDataTemp));
      if (JSON.stringify(this.formDataTemp) == '{}' || !this.formDataTemp['title'] || !this.formDataTemp[
          'imgUrl']) {
        alert('请补充数据');
        return;
      }
      let reqUrl = util.serverUrl + '/cate'
      this.formDataTemp.parentId = this.parentCateId
      this.$http.post(reqUrl, this.formDataTemp).then(function (res) {
        console.log("saved Id:" + res.body.id);
        this.loadCateList();
      }, function (res) {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    submitModifyGood() {
      console.log('modify good:' + this.formDataTemp.id);
      let reqUrl = util.serverUrl + "/good/" + this.formDataTemp.id;
      this.$http.post(reqUrl, this.formDataTemp).then(function () {
        this.loadCateOrGoodList(null, 'page');
        alert('修改成功');
      }, function (res) {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    submitAddGood() {
      console.log('add good');
      // console.log('data:'+JSON.stringify(this.formDataTemp));
      if (JSON.stringify(this.formDataTemp) == '{}' || !this.formDataTemp['title'] || !this.formDataTemp[
          'imgUrl']) {
        alert('请补充数据');
        return;
      }
      this.formDataTemp['cateId'] = this.parentCateId;
      let reqUrl = util.serverUrl + '/good'
      this.$http.post(reqUrl, this.formDataTemp).then(function (res) {
        console.log("saved Id:" + res.body.id);
        let cate = {}
        cate.id = this.parentCateId
        this.loadCateOrGoodList(cate, 'page');
      }, function (res) {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    navToSlide() {
      this.isActive = ['', 'active', '', ''];
      this.tabNum = 2;
      this.loadSlideData()
    },
    navToSale() {
      console.log('jump to sale')
      this.isActive = ['', '', 'active', ''];
      this.tabNum = 3;
      let reqUrl = util.serverUrl + "/sale"
      this.$http.get(reqUrl).then(
          (res) => {
            this.saleInfo = res.body;
          },
          (res) => {
            alert(res.body.msg);
            if (res.status == 401) {
              this.$router.push("/login");
            }
          }
      );
    },
    navToOtherInfo() {
      console.log('jump to other')
      this.isActive = ['', '', '', 'active'];
      this.tabNum = 5;
      let reqUrl = util.serverUrl + "/other/list";
      this.$http.get(reqUrl).then((res) => {
        this.otherInfos = res.data.content;
      }, (res) => {
        alert(res.data.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    //展示师傅列表
    navToWorker() {
      console.log('jump to worker')
      this.isActive = ['', '', '', '', 'active'];
      this.tabNum = 6;
      this.workerInfo = {content: [], pageable: {pageNumber: 0}}
      this.listWorker('no')
    },
    listWorker(action = 'next') {
      let page = 0;
      if (this.workerInfo.pageable != undefined) {
        page = this.workerInfo.pageable.pageNumber
      }
      if (action == 'next') {
        if (this.workerInfo.last) {
          alert("已是最后一页")
          return
        }
        page++;
      } else if (action == 'pre') {
        if (this.workerInfo.first) {
          alert("已是第一页")
          return
        }
        page--;
      }
      console.log('page:' + page)
      let reqUrl = util.serverUrl + "/worker?page=" + page+'&titleKey=' + this.workerKey +'&open=-1'
      this.$http.get(reqUrl).then((res) => {
        this.workerInfo = res.data;
      }, (res) => {
        alert(res.data.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    loadSlideData() {
      let reqUrl = util.serverUrl + '/slide';
      this.$http.get(reqUrl).then((res) => {
        console.log('load slide success');
        this.slideInfos = res.body.content;
      }, (res) => {
        console.log('error');
        alert(res.body.msg)
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    deleteSlide(slide) {
      let reqUrl = util.serverUrl + '/slide/' + slide.id;
      this.$http.delete(reqUrl).then((res) => {
        alert('删除成功');
        this.loadSlideData();
      }, (res) => {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    addSlide() {
      this.actionType = 5;
      this.formDataTemp = {};
      this.clearInput()
    },
    submitAddSlide() {
      let reqUrl = util.serverUrl + "/slide";
      this.$http.post(reqUrl, this.formDataTemp).then((res) => {
        this.slideInfos.push(res.body);
      }, (res) => {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    modifySaleInfo() {
      let reqUrl = util.serverUrl + "/sale/" + this.saleInfo.id;
      this.$http.post(reqUrl, this.saleInfo).then((res) => {
        this.saleInfo = res.body;
        alert('修改成功');
      }, (res) => {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    submitModifyOther() {
      let reqUrl = util.serverUrl + "/other/" + this.formDataTemp.id;
      this.$http.post(reqUrl, this.formDataTemp).then(function () {
        this.navToOtherInfo();
        alert('修改成功');
      }, function (res) {
        alert(res.body.msg);
        if (res.status == 401) {
          this.$router.push("/login");
        }
      });
    },
    goPage(action = 'next') {
      if (action == 'next') {
        if (this.pageDisable[1] == 'disabled') {
          alert('已经到最后一页');
          return;
        }
        this.pageNum += 1;
      } else {
        if (this.pageDisable[0] == 'disabled') {
          alert('已经到第一页');
          return;
        }
        this.pageNum -= 1;
      }
      if (this.tabNum == 1) {
        this.loadCateList();
      } else if (this.tabNum == 4) {
        let cate = {};
        cate['id'] = this.parentCateId;
        this.loadCateOrGoodList(cate, 'page');
      } else if (this.tabNum == 6) {
        //todo worker next pre
        this.listWorker(this.pageNum - 1)
      }
    },
    changePreLast(data) {
      if (data.first) {
        this.pageDisable[0] = 'disabled';
      } else {
        this.pageDisable[0] = '';
      }
      if (data.last) {
        this.pageDisable[1] = 'disabled';
      } else {
        this.pageDisable[1] = '';
      }
    },
    // 删除工作者信息

    deleteWorker(e) {
      // event.target  当前点击的元素
      // event.currentTarget 当前绑定事件的元素
      // event.currentTarget.innerHTML 当前绑定事件的元素的html
      // event.currentTarget.innerText 当前绑定事件的元素的文本
      console.log('delete worker: ' + JSON.stringify(e))
      var id = e.target.dataset.id;
      console.log('id :' + id)
      let url = util.serverUrl + "/worker/" + id
      this.$http.delete(url).then(res => {
        console.log('delete ok')
        let workers = []
        for (let i = 0; i < this.workerInfo.content.length; i++) {
          let worker = this.workerInfo.content[i]
          if (worker.id != id) {
            workers.push(worker)
          }
        }
        this.workerInfo.content = workers
        alert('删除成功')
      }, error => {
        alert('删除失败')
      })
    },
    modifyWorker(e) {
      let index = e.target.dataset.index;
      console.log('id :' + index)
      let worker = this.workerInfo.content[index]
      if (worker.title == '' || worker.telephone == '') {
        alert('师傅的名字和电话不能为空')
        return
      }
      let url = util.serverUrl + '/worker'
      this.$http.post(url, worker).then(
          res => {
            console.log('保存数据成功:' + index)
            this.navToWorker()
            alert('更新成功')
          },
          error => {
            alert("更新失败")
          }
      )
    },
    addWorker() {
      let worker = {}
      worker.title = ''
      worker.telephone = ''
      this.workerInfo.content.unshift(worker)
      this.clearInput()
    }
  },
  data() {
    return {
      isActive: ['active', '', '', '', ''],
      // 1分类列表，2轮播图片管理，3特价信息管理，4商品列表,5是其他信息管理
      tabNum: 1,
      cateList: [],
      goodList: [],
      // addTocateId: 0,
      deleteGood: {},
      deleteCate: {},
      deleteTip: '',
      formDataTemp: {},
      // 1为分类修改，2为分类新增，3商品的修改，4商品的新增,5添加幻灯皮，6修改其他信息
      actionType: 2,
      slideInfos: [],
      saleInfo: {},
      otherInfos: [],
      workerInfo: {content: [], pageable: {pageNumber: 0}},
      pageDisable: ['disabled', ''],
      pageNum: 1,
      parentCateId: 0,
      workerKey: '',
      parentNavName: '全部分类',
      loading: false
    }
  },
  beforeMount() {
    let userinfo = this.getToken();
    console.log(userinfo);
    if (userinfo == null || userinfo == undefined) {
      console.log('跳转回去');
      this.$router.push("/login");
      return;
    } else {
      this.loadCateList();
    }
  }
}
</script>

<style>
.content-admin {
  width: 86%;
  margin-top: 20px !important;
  border: 2px solid #007bff;
  border-radius: 8px;
}

.content-admin > div {
  margin: 10px 10px;
}

.cate-box {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-top: 3px solid #062C33;
  padding: 10px 10px;
}

.cate-box > div {
  margin: 4px 4px;
}

.cate-box > div:hover {
  border: 2px dashed #138496;
}

.cate-item article {
  width: 240px;
}

.card-body img {
  width: 24px;
  float: right;
  margin-left: 14px;
  vertical-align: bottom !important;
}

.btn-primary {
  font-size: 10px;
}

.title-page {
  height: 54px;
}

.worker-oper {
  display: flex;
  display: -webkit-flex;
}
.search-box{
  margin-left: 30px;
}
</style>
